<template>
<div class="container-fluid">
    <ConfirmAlert ref="MyConfirmAlert" :title="AlertTitle" :message="AlertMessage" @Sure="YesIamSure($event)" :sureResult="sureResult" />
    <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" />
                <div class="col-md-12">
                  <div class="col-lg-12 col-md-12 ">
                    <div class="row">
                      <div class="col-md-4">
                        <form @submit.prevent="onSearch()" class="input-group no-border">
                            <input type="text" v-model="filter.name" class="form-control" placeholder="بحث">
                            <button @click="onSearch()" type="submit" class="btn btn-white btn-round btn-just-icon">
                                <i class="material-icons">search</i>
                                <div class="ripple-container"></div>
                            </button>
                        </form>
                      </div>
                      <div class="col-md-4">
                         <PerPage id="limit" title="كل صفحة"  Mykey="limit"  :MyValue="filter.limit" @Select="HandleSelect('limit',$event)"/>
                      </div>
                      <div class="col-md-4">
                          <router-link v-if="manageService('can_add')" :to="{name: 'admin.dashboard.user.create'}" class="btn btn-primary btn-md" > <i class="fas fa-pencil-alt"> </i>
                             اضافة مستخدم</router-link>
                      </div>
                    
                    </div>
                  </div>
                </div>

            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary" style="text-align: center;">
                  <h4 class="card-title ">قائمة المستخدمين</h4>
                  <p class="card-category">قائمة بالمستخدمين الموجودين بالنظام </p>
                </div>
                <div class="card-body" style="text-align:center;">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th>
                          #
                        </th>
                        <th>
                          الاسم
                        </th>
                        <th>
                          البريد الإلكتروني
                        </th>
                        <th>
                         الدور الوظيفي
                        </th>
                        <th>
                          إجراءات
                        </th>

                      </thead>
                      <tbody>
                        <tr v-for="(user,index) in UsersList.data" :key="index">
                          <td>
                            {{index+1}}
                          </td>
                          <td>
                            {{user.name}}
                          </td>
                          <td>
                            {{user.email}}
                          </td>
                          <td>
                            {{user.role[0]}}
                          </td>


                          <td class="text-primary">
                            <router-link :to="{name: 'admin.dashboard.user.details', params: {id: user.id}}" class="btn btn-success btn-sm" > <i class="fas fa-pencil-alt"> </i>
                             تفاصيل</router-link>
                            <router-link v-if="manageService('can_update')" :to="{name: 'admin.dashboard.user.edit', params: {id: user.id}}" class="btn btn-warning btn-sm" > <i class="fas fa-pencil-alt"> </i>
                             تعديل</router-link>
                            <a href="" v-if="manageService('can_delete')" class="btn btn-danger btn-sm" @click.prevent="ShowConfirm(user)">
                             حذف</a> 
                          </td>
                        </tr>
                       </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
             <pagination v-if="UsersList.last_page > 1" :pagination="UsersList" :offset="5" @paginate="onPaginationChage"></pagination> 
             </div>
</div>
</template>

<script>
import store from '../../../store/index'
import {mapState} from 'vuex';
import ConfirmAlert from '../../../components/ConfirmAlert'
import SuccessAlert from '../../../components/SuccessAlert'
import Pagination from '../../../components/Pagination.vue'
import PerPage from '../../../components/PerPage'



export default {
  data(){
   return{
   filter:{
      name:'',
      page:1,
      paginate:1,
      limit:100,
    },  
     AlertTitle:'',
     AlertMessage:'',
     SelectedUser:null,
     sureResult:true,
   }
  },
  
  computed:{
    ...mapState({
        UsersList:state=>state.admin.users.UsersList,
    }),
    manageService: (state) => (service) => {
            return store.getters['admin/canManageService'](service);
    },
   },
   methods:{
      HandleSelect(key,value){
         this.filter[key]=value;
         this.onPaginationChage(1);
     },
        onPaginationChage(page){
         this.filter.page=page;
         store.dispatch('admin/fetchUsersList',this.filter).then((response)=>{
         }).catch((error)=>{
         })
       },
       onSearch(){
         store.commit('admin/PleaseStartLoading');
            this.filter.page=1;
            store.dispatch('admin/fetchUsersList',this.filter).then(()=>{
              store.commit('admin/PleaseStopLoading');
            }).catch((error)=>{
              store.commit('admin/PleaseStopLoading');
            });  
        },
     YesIamSure(value){
       if(value && this.sureResult){
         this.sureResult=false;
          this.onDelete();
       }
     },
     CancelAlert(){
      this.sureResult=false;
     },
     ShowConfirm(User){
            this.AlertTitle="معلومات",
            this.AlertMessage="هل انت متاكد من الحذف ؟";
            this.$refs.MyConfirmAlert.showModel();
            this.SelectedUser=User;
     },
         onDelete(){
           store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/deleteUser',this.SelectedUser.id).then((Response)=>{
            store.commit('admin/PleaseStopLoading');
            this.sure=false;
           this.$refs.MyConfirmAlert.hideModel();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الحذف بنجاح";
            this.$refs.MySuccessAlert.showModel();
            store.dispatch('admin/fetchUsersList',{page:1,name:'',paginate:1,limit:100}).then(()=>{
             this.sureResult=true;
            }).catch(error=>{
              store.commit('admin/PleaseStopLoading');
            })
          }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
        });
        

    },
   },
      components:{
       ConfirmAlert,
       SuccessAlert,
       Pagination,
       PerPage
   },

  beforeRouteEnter(to,from,next){    
    store.commit('admin/PleaseStartLoading');
      store.dispatch('admin/fetchUsersList',{page:1,name:'',paginate:1,limit:100}).then(()=>{
        store.commit('admin/PleaseStopLoading');
         next(true);
      }).catch((error)=>{
        store.commit('admin/PleaseStopLoading');
       next(false);
      });             

    },
}
</script>

<style>

</style>